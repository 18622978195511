<template>
  <div class="grid-container pt-30">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6 medium-offset-3">
        <h1>Dash - Reset Password</h1>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-3">
      <div class="cell small-12 medium-6 medium-offset-3">
        <form @submit.prevent="resetPassword">
          <label>Password
            <input
              aria-describedby="passwordHelpText"
              name="password"
              placeholder="password"
              type="password"
              v-model="password">
          </label>
          <p class="help-text" id="passwordHelpText">Please enter your new password</p>
          <label>Repeat Password
            <input
              aria-describedby="passwordHelpText"
              name="password"
              placeholder="repeat password"
              type="password"
              v-model="verifyPassword">
          </label>
          <p class="help-text" id="passwordHelpText">Please repeat your new password</p>
          <div class="pt-3">
            <button class="button">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
    <app-message
      v-if="showMessage === true"
      :messageText="message"
      :link="linkUri"
      :linkText="linkText"
      @closeMessage="showMessage = false" />
  </div>
</template>

<script>
import Message from '@/components/messages/Message.vue';
import axios from '../../axios';

export default {
  name: 'ResetPassword',
  components: {
    appMessage: Message,
  },
  data() {
    return {
      hashVerified: false,
      linkUri: false,
      linkText: false,
      message: '',
      password: '',
      showMessage: false,
      verifyPassword: '',
    };
  },
  methods: {
    checkHash() {
      const postData = {};
      postData.hash = this.$route.params.hash;
      postData.userid = this.$route.params.userid;
      axios.post('/users/checkResetHash.json', postData)
        .then((response) => {
          this.showMessage = true;
          this.message = response.data.message;
          this.hashVerified = response.data.verified;
        });
    },
    resetPassword() {
      const postData = {};
      if (this.password === this.verifyPassword) {
        postData.password = this.password;
        postData.userid = this.$route.params.userid;
        axios.post('/users/resetPassword.json', postData)
          .then((response) => {
            this.showMessage = true;
            this.message = response.data.message;
            this.linkUri = '/';
            this.linkText = 'Login';
          });
      } else {
        this.showMessage = true;
        this.message = 'Sorry, those passwords do not match. Please re-enter.';
      }
    },
  },
  mounted() {
    this.checkHash();
  },
};
</script>
